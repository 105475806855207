<template>
    <div class="box">
        <h2>
            Blacklist verwalten
            <small>Prüfung ob ein eingegebener Name zu einem Unternehmen gehört.</small>
        </h2>
        <div class="body">
            <h3 style="margin: 0; padding:0;">Prüfung auf alleinstehende Begriffe</h3>
            <small>
                Die hier eingetragenen Begriffe müssen im Feld als eigenständiges Wort vorkommen. Das bedeutet es muss entweder am Beginn, Ende oder durch Leerzeichen getrennt vorkommen. Groß- und
                Kleinschreibung wird dabei nicht beachtet.
            </small>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(filter, index) in blacklist.alleinstehend" v-bind:key="index">
                        <td>
                            <div class="form-group form-float no-error">
                                <input
                                    v-model="blacklist.alleinstehend[index]"
                                    :name="'filter_alleinstehend_' + index"
                                    v-validate="'required'"
                                    :id="'filter_alleinstehend_' + index"
                                    placeholder=" "
                                    type="text"
                                />
                                <label :for="'rule_until_' + index">Filter</label>
                                <span class="error" v-show="errors.has('filter_alleinstehend_' + index)">{{ errors.first("filter_alleinstehend_" + index) }}</span>
                            </div>
                        </td>
                        <td><i @click="removeAlleinstehend(index)" class="delete fal fa-trash-alt"></i></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <span class="add" @click="addAlleinstehend()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <h3 style="margin:0; padding:0;">Prüfung auf vorkommen irgendwo im Feld</h3>
            <small>Die hier eingegebenen Begriffe können irgendwo im Feld, auch innerhalb von Wörtern vorkommen. Groß- und Kleinschreibung wird dabei nicht beachtet.</small>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(filter, index) in blacklist.beliebig" v-bind:key="index">
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="blacklist.beliebig[index]" :name="'filter_beliebig_' + index" v-validate="'required'" :id="'filter_beliebig_' + index" placeholder=" " type="text" />
                                <label :for="'filter_beliebig_' + index">Filter</label>
                                <span class="error" v-show="errors.has('filter_beliebig_' + index)">{{ errors.first("filter_beliebig_" + index) }}</span>
                            </div>
                        </td>
                        <td><i @click="removeBeliebig(index)" class="delete fal fa-trash-alt"></i></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <span class="add" @click="addBeliebig()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <div class="button-row">
                <button @click="save()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            blacklist: {
                alleinstehend: [],
                beliebig: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        addAlleinstehend() {
            this.blacklist.alleinstehend.push("");
        },
        addBeliebig() {
            this.blacklist.beliebig.push("");
        },
        removeAlleinstehend(index) {
            this.blacklist.alleinstehend.splice(index, 1);
        },
        removeBeliebig(index) {
            this.blacklist.beliebig.splice(index, 1);
        },
        save() {
            var blacklist = {
                alleinstehend: [],
                beliebig: []
            };
            for (let f = 0; f < this.blacklist.alleinstehend.length; f++) {
                if (this.blacklist.alleinstehend[f].trim() == "") {
                    continue;
                }

                blacklist.alleinstehend.push(this.blacklist.alleinstehend[f]);
            }
            for (let f = 0; f < this.blacklist.beliebig.length; f++) {
                if (this.blacklist.beliebig[f].trim() == "") {
                    continue;
                }

                blacklist.beliebig.push(this.blacklist.beliebig[f]);
            }

            this.$api.put("/ktvm/blacklist_unternehmen", blacklist).then(
                () => {
                    this.$snotify.success("Filter gespeichert");
                },
                () => {
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.$api.get("/ktvm/blacklist_unternehmen").then(
                response => {
                    if (response.data.data === null) {
                        response.data.data = {
                            alleinstehend: [],
                            beliebig: []
                        };
                    }
                    if (response.data.data.alleinstehend === null) {
                        response.data.data.alleinstehend = [];
                    }
                    if (response.data.data.beliebig === null) {
                        response.data.data.beliebig = [];
                    }

                    this.blacklist = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Filter");
                }
            );
        }
    }
};
</script>
